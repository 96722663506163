import { useState, memo, useEffect } from 'react';

import { useRouter } from 'next/navigation';

import { Session } from 'next-auth';

import { useSession } from 'next-auth/react';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

import {
  Dialog, DialogContent, DialogDescription, DialogTitle,
} from '../ui/dialog';

const EmailVerificationModal = ({ param, session }:{ param:string, session:Session | null }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(param === '2');

  const { update } = useSession();
  const router = useRouter();

  const handleClose = async () => {
    setIsOpen(false);
    router.push('/');
  };

  useEffect(() => {
    const handleUpdateEmailVerified = async ():Promise<void> => {
      if (!session) return;
      try {
        const request = await fetch('/api/check?type=1');
        const response = await request.json();
        if (!response.error && response.status) {
          setIsVerified(true);
          await update({ email_verified: true, triggerType: 'emailVerified' });
        }
      } catch (error) {
        setIsLoaded(false);
        setIsVerified(false);
      } finally {
        setIsLoaded(true);
      }
    };
    if (param === '2' && !isLoaded) handleUpdateEmailVerified();
  }, [isLoaded, param, update, session]);

  return (
    <Dialog open={param !== null && isOpen} onOpenChange={handleClose}>
      <DialogContent className="flex flex-col items-center justify-center bg-sivarbet-background rounded-lg border-sivarbet-border">
        {isVerified ? (
          <>
            <FaCheckCircle className="text-green-600 text-7xl" />
            <DialogTitle className="text-center text-2xl font-bold">¡Correo verificado!</DialogTitle>
            <DialogDescription className="text-center text-lg">
              Tu correo ha sido verificado exitosamente. Si no has hecho tu primer depósito, hazlo ahora para que
              disfrutes de toda la emoción!
            </DialogDescription>
          </>
        ) : (
          <>
            <FaTimesCircle className="text-red-600 text-7xl" />
            <DialogTitle className="text-center text-2xl font-bold">Error de verificación</DialogTitle>
            <DialogDescription className="text-center text-lg">
              Lo sentimos, no pudimos verificar tu correo electrónico. Por favor, intenta nuevamente más tarde o contacta a soporte.
            </DialogDescription>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default memo(EmailVerificationModal);
