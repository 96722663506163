import imageUrlBuilder from '@sanity/image-url';
import { createClient } from 'next-sanity';

import { apiVersion, dataset, projectId } from './env';

const client = createClient({
  projectId,
  dataset,
  useCdn: true,
  apiVersion,
});

const builder = imageUrlBuilder(client);

function urlFor(source: string) {
  return builder.image(source);
}

export default urlFor;
