'use client';

import React, { Suspense } from 'react';

import LoadingSpinner from '@/components/common/LoadingSpinner';
import HomeMain from '@/components/home/HomeMain';
import NavigationStructure from '@/components/navbar/NavigationStructure';

export default function Home() {
  return (
    <NavigationStructure>
      <div className="flex flex-col items-stretch max-w-full overflow-hidden px-4 sm:px-8">
        <Suspense fallback={<LoadingSpinner />}>
          <HomeMain />
        </Suspense>
      </div>
    </NavigationStructure>
  );
}
