/* eslint-disable no-console */

import { BonusCasinoGameType, GameResult } from '@/enums/core';

import { prisma } from '@/utils/prisma';

export async function findRollback(actionids: string[]) {
  try {
    const rollbackRecords = await prisma.games.findMany({
      where: {
        i_rollback: {
          in: actionids,
        },
        result: GameResult.ROLLBACK,
      },
      select: {
        i_actionid: true,
        i_rollback: true,
        id: true,
        created_at: true,
        i_gameid: true,
      },
    });
    return rollbackRecords;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}

export async function batchUpdateRollback(rollbackUpdates: { id_rollback: string, value: number }[]) {
  try {
    // Create an array of update operations
    const rollbackIds = rollbackUpdates.map((update) => { return update.id_rollback; });

    // Find all rollback records in one query
    const rollbackRecords = await prisma.games.findMany({
      where: {
        i_rollback: { in: rollbackIds },
        result: GameResult.ROLLBACK,
      },
      select: {
        id: true,
        i_rollback: true,
      },
    });

    // Prepare update operations
    const updates = rollbackRecords.map((record) => {
      const updateData = rollbackUpdates.find((update) => { return update.id_rollback === record.i_rollback; });
      return prisma.games.update({
        where: {
          id: record.id,
        },
        data: {
          amount: updateData?.value ?? 0, // Update the amount with the correct value
        },
      });
    });

    // Run all updates in a transaction
    await prisma.$transaction(updates);

    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
}

export async function findDuplicateGames(actionIds: string[]) {
  try {
    const dupes = await prisma.games.findMany({
      where: {
        i_actionid: {
          in: actionIds,
        },
      },
      select: {
        i_actionid: true,
        id: true,
        created_at: true,
        i_gameid: true,
      },
    });
    return dupes;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}

export async function createGames(actions: any[]) {
  try {
    if (actions.length === 0) {
      return [];
    }

    if (actions.length === 1) {
      // Optimize for the most common case (90% of the time)
      const action = actions[0];
      const createdGame = await prisma.games.create({
        data: {
          user_id: action.user_id,
          provider: action.provider,
          result: action.result,
          amount: action.amount,
          currency: action.currency,
          i_gameid: action.i_gameid,
          i_gamedesc: action.i_gamedesc,
          i_actionid: action.i_actionid,
          jackpot_win: action.jackpot_win,
          rolled_back: action.rolled_back,
          i_rollback: action.i_rollback,
        },
      });
      return [createdGame];
    }

    // Handle multiple records with array map and Promise.all
    return await prisma.$transaction(async (tx:any) => {
      const createdGames = Promise.all(
        actions.map((action) => {
          return tx.games.create({
            data: {
              user_id: action.user_id,
              provider: action.provider,
              result: action.result,
              amount: action.amount,
              currency: action.currency,
              i_gameid: action.i_gameid,
              i_gamedesc: action.i_gamedesc,
              i_actionid: action.i_actionid,
              jackpot_win: action.jackpot_win,
              rolled_back: action.rolled_back,
              i_rollback: action.i_rollback,
            },
          });
        }),
      );
      return createdGames;
    });
  } catch (error) {
    console.log('error', error);
    return [];
  }
}

export async function updateFreespin(issue_id: number, status: number, total_amount: number): Promise<any> {
  try {
    // Start a transaction
    const result = await prisma.$transaction(async (p) => {
      // First operation: Update freeSpins
      const updatedFreespin = await p.freeSpins.update({
        where: {
          id: issue_id,
        },
        data: {
          status,
          amount_won: total_amount,
          updated_at: new Date(),
        },
        select: {
          games: true,
          user_id: true,
        },
      });

      // Second operation: Get user balance, contingent on success of first operation
      const userBalance = await p.users.findFirst({
        where: {
          id: updatedFreespin.user_id,
        },
        select: {
          deposit_balance: true,
          available_balance: true,
        },
      });
      const date = new Date().getTime().toString();
      await p.games.create({
        data: {
          user_id: updatedFreespin.user_id,
          provider: 1,
          result: total_amount > 0 ? 2 : 1,
          amount: total_amount,
          currency: 1,
          i_gameid: date,
          i_gamedesc: updatedFreespin?.games?.toString() ?? '',
          i_actionid: `${BonusCasinoGameType.FREESPINS}_${date}`,
          jackpot_win: 0,
          rolled_back: false,
          i_rollback: null,
        },
      });

      return userBalance;
    });

    return result;
  } catch (error) {
    console.log('error', error);
    return [];
  }
}

export async function findRollbackAmount(id_action: string) {
  try {
    const rollbackAmount = await prisma.games.findFirst({
      where: {
        i_actionid: id_action,
        result: {
          not: GameResult.ROLLBACK,
        },
      },
      select: {
        amount: true,
      },
    });
    if (rollbackAmount) {
      return rollbackAmount.amount;
    }
    return 0;
  } catch (error) {
    console.log('error', error);
    return 0;
  }
}

export async function markGamesAsRolledBack(originalActionIds: any[]) {
  try {
    // Create an array of update operations for each record
    const updateOperations = originalActionIds.map(({ originalActionId, rollbackActionId }) => {
      return prisma.games.updateMany({
        where: {
          i_actionid: originalActionId,
        },
        data: {
          rolled_back: true,
          i_rollback: rollbackActionId,
        },
      });
    });

    await prisma.$transaction(updateOperations);

    return true;
  } catch (error) {
    console.log('error', error);
    return false;
  }
}

export async function createBonus(actions: any) {
  try {
    return await prisma.betBonuses.create({
      data: actions,
    });
  } catch (error) {
    console.log('error', error);
    throw error;
  }
}

export function CategoriesGames(data: any, IconCategory: any, type: string) {
  if (!data.gameData || data.gameData.length === 0) {
    return [];
  }
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const gamesFiltered = data.gameData.filter((game: any) => { return Object.keys(game.sections).length > 0; });
  const setedCategory = data.sectionsGames.map((category: any) => {
    return {
      headline: capitalizeFirstLetter(category),
      items: gamesFiltered.filter((game: any) => { return game.sections[category]; }).sort((a: any, b: any) => { return a.sections[category] - b.sections[category]; }),
      url: '/casino',
      icon: IconCategory[category as keyof typeof IconCategory],
    };
  });

  const allGamesFiltered = data.sectionsGames.map((category: any) => {
    return {
      headline: capitalizeFirstLetter(category),
      items: data.gameData
        .filter((game: any) => {
          if (category === 'live') return game.has_live;
          if (category === 'nuevos') {
            const today = new Date();
            const releaseDate = new Date(game.released_at);
            if (Number.isNaN(releaseDate.getTime())) {
              // Exclude games with invalid or missing release dates
              return false;
            }
            const diffTime = today.getTime() - releaseDate.getTime();
            const diffDays = diffTime / (1000 * 60 * 60 * 24);
            return diffDays >= 0 && diffDays <= 90;
          }
          if (['popular', 'recomendados'].includes(category)) return game.sections && game.sections[category];

          return game.category === category;
        })
        .sort((a: any, b: any) => {
          if (category === 'live') return 0;
          if (category === 'nuevos') {
            const dateA = new Date(a.released_at).getTime();
            const dateB = new Date(b.released_at).getTime();
            return dateB - dateA; // Newest games first
          }
          if (['popular', 'recomendados'].includes(category)) {
            return a.sections[category] - b.sections[category];
          }
          return a.title - b.title;
        }),
      url: '/casino',
      icon: IconCategory[category as keyof typeof IconCategory],
    };
  });
  const categories = type === 'home' ? setedCategory : allGamesFiltered;

  return categories;
}

export const burnedCategories = (data: any, IconCategory: any) => {
  const burned = [
    {
      headline: 'Juega en Dolares',
      items: data.filter((game: any) => { return game.usd_only === true; }),
      url: '/casino',
      icon: IconCategory.usd,
    },
    {
      headline: 'Rasca y Gana',
      items: data.filter((game: any) => { return game.category === 'scratch'; }),
      url: '/casino',
      icon: IconCategory.scratch,
    },
    {
      headline: 'Tragamonedas',
      items: data.filter((game: any) => { return game.category === 'slots'; }),
      url: '/casino',
      icon: IconCategory.slots,
    },
    {
      headline: 'Ruleta',
      items: data.filter((game: any) => { return game.category === 'roulette'; }),
      url: '/casino',
      icon: IconCategory.roulette,
    },
    {
      headline: 'Cartas',
      items: data.filter((game: any) => { return game.category === 'card'; }),
      url: '/casino',
      icon: IconCategory.card,
    },
    {
      headline: 'Loteria',
      items: data.filter((game: any) => { return game.category === 'lottery'; }),
      url: '/casino',
      icon: IconCategory.lottery,
    },
    {
      headline: 'Dados',
      items: data.filter((game: any) => { return game.category === 'craps'; }),
      url: '/casino',
      icon: IconCategory.dice,
    },
    {
      headline: 'Arcade',
      items: data.filter((game: any) => { return game.category === 'casual'; }),
      url: '/casino',
      icon: IconCategory.casual,
    },
  ];

  return burned;
};
