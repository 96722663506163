import React from 'react';

import { useRouter } from 'next/navigation';

import { CiCircleAlert } from 'react-icons/ci';

import { userUIStore } from '@/stores/userUIStore';

import { Button } from '../ui/button';
import {
  Dialog, DialogContent, DialogHeader, DialogTitle,
} from '../ui/dialog';

const AcceptModal = ({ url }: { url: string }) => {
  const { isAcceptModalOpen, toggleAcceptModal } = userUIStore();
  const router = useRouter();
  return (
    <Dialog open={isAcceptModalOpen} onOpenChange={toggleAcceptModal} modal>
      <DialogContent className="max-w-lg mx-auto border-sivarbet-border bg-sivarbet-background text-sivarbet-text rounded-lg shadow-2xl shadow-black ">
        <DialogHeader className="flex flex-col items-center justify-center w-full">
          <CiCircleAlert className="text-red-500 text-4xl" />
          <DialogTitle className="text-center text-xl font-bold">Importante!</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-3">
          <p className="text-center text-wrap">
            Este juego es solo en dolares, ten en cuenta que
            <br />
            <span className="font-bold">1 USD = 25 HNL</span>
          </p>
          <div className="flex justify-center gap-3">
            <Button onClick={() => { router.push(url); toggleAcceptModal(); }} className="bg-sivarbet-secondary hover:bg-sivarbet-secondary/90 text-sivarbet-text">Aceptar</Button>
            <Button onClick={() => { toggleAcceptModal(); }} className="bg-red-500 hover:bg-red-500/90 text-sivarbet-text">Cancelar</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptModal;
